<template>
  <div>
    <portal to="subheader_portal">
      <router-link :to="{ name: 'core_buy_create' }">
        <b-button variant="success"
          ><b-icon icon="plus" aria-hidden="true"></b-icon> Nuevo</b-button
        >
      </router-link>
    </portal>

    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <b-table
              :items="items"
              :fields="fields"
              head-variant="light"
              caption-top
            >
              <template #cell(document)="data">
                {{ data.item.serie }}-{{ data.item.number }}
              </template>

              <template #cell(is_active)="data">
                <toggle-button
                  v-model="data.item.is_active"
                  @change="deactivateItem(data.item)"
                  :value="data.item.is_active"
                  :labels="{ checked: 'ON', unchecked: 'OFF' }"
                  :color="{
                    checked: '#1BC5BD',
                    unchecked: '#F64E60',
                    disabled: '#CCCCCC',
                  }"
                />
              </template>
              <template #cell(actions)="data">
                <b-button
                  v-if="data.item.is_closed === false"
                  size="sm"
                  class="mb-2 mr-2"
                  variant="primary"
                  @click="editItem(data.item)"
                  title="Editar"
                >
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                </b-button>
                <b-button
                  v-if="data.item.is_closed === false"
                  size="sm"
                  class="mb-2 mr-2"
                  variant="warning"
                  @click="closeItem(data.item)"
                  title="Editar"
                >
                  <b-icon icon="lock" aria-hidden="true"></b-icon>
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="pageSize"
              @change="handlePageChange"
            ></b-pagination>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import config from "@/core/config/layout.config.json";

export default {
  name: "ClientList",
  methods: {
    closeItem(item) {
      ApiService.patch(`core/purchase/${item.id}/`, { is_closed: true }).then(
        () => {
          this.getItems();
        }
      );
    },
    editItem(item) {
      this.$router.push({
        name: `core_buy_edit`,
        params: { id: item.id },
      });
    },
    getItems() {
      ApiService.get(
        "core/purchase",
        `?page_size=${this.pageSize}&page=${this.currentPage}`
      ).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.count;
      });
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.getItems();
    },
    isValidForm() {},
  },
  data() {
    return {
      items: [],
      fields: [
        { key: "document", label: "Documento" },
        { key: "provider_name", label: "Proveedor" },
        { key: "issue_date", label: "Fecha emisión" },
        { key: "total", label: "Total" },
        { key: "actions", label: "" },
      ],
      currentPage: 1,
      rows: 0,
      pageSize: config.itemsPerPage,
    };
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style scoped></style>
